import React from 'react';
import {getScores} from "../../modules/scores";
import moment from "moment/moment";
import {convertToEmojis} from "../../modules/uiutils";
import {Link} from "react-router-dom";

const GameOver = ({ score, module, handleRestart }) => {
  const latestScores = getScores(module);
  return (
    <div className="game-over">
      <div>
        <Link to="/">← back to main page</Link>
      </div>
      <div className="game-over-text">
        <h1>Oh no! 😔</h1>
        <p>Don't worry though, you gave it your best shot! 👏 Remember, the more you play, the more you learn and improve. 💪</p>
        <p>Your final score was {convertToEmojis(score)} points.</p>
        {latestScores.length > 0 ? (
          <>
            <p>Your most recent scores are:</p>
            <ul>
              {latestScores.map((score, index) => ( <li className="scores" key={index}>🌟 {convertToEmojis(score.score)} achieved {moment(score.dateTime).fromNow()}</li> ))}
            </ul>
          </>
          ): null}
        <p>Take a deep breath and try again! Who knows, you might just beat your previous score! 🤞</p>
        <p>Let's get started and see how many points you can earn this time! 🚀</p>
      </div>
      <button className="restart-button" onClick={handleRestart}>Start Again Now</button>
    </div>
  );
};

export default GameOver;
