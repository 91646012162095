import React from "react";
import Pacman from 'react-pacman';

const Level = ({ onStartNextLevel }) => (
  <div className="level-start">
    <h2>Level completed! 🎉🎉🎉</h2>
    <p>You did it! 🥳🥳🥳 You've shown that you have the skills to conquer each level of our game. </p>
    <p>Now please relax and enjoy a bit of Pacman before the next level.</p>
    <Pacman />
    <p>Keep up the great work, and let's continue on to the next level! 💪</p>
    <button onClick={onStartNextLevel} className="level-start-button">Start next level</button>
  </div>
);

export default Level;