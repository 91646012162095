import React, {useEffect, useState} from 'react';
import Timer from "../components/timer/Timer";
import Question from "../components/Question";
import Answer from "../components/Answers";
import Score from "../components/Score";
import GameStart from "../components/gamestart/GameStart";
import GameOver from "../components/gameover/GameOver";
import Lives from "../components/Lives";
import Level from "../components/Level";
import {generateTimesTableQuestions} from "../modules/timestable";
import {generateAdditionAndSubtractionQuestions} from "../modules/additionandsubtraction";
import {updateHighestScore, updateScores} from "../modules/scores";
import GameWon from "../components/gamewon/GameWon";
import {useParams} from "react-router-dom";

const StartGamePage = () => {

  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(-1);
  const [score, setScore] = useState(0);
  const [lives, setLives] = useState(3);
  const [time, setTime] = useState(0);
  const [timerKey, setTimerKey] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [gameWon, setGameWon] = useState(false);
  const [levelCompleted, setLevelCompleted] = useState(false);
  const [level, setLevel] = useState(1);
  const [correctPosition, setCorrectPosition] = useState(-1);
  const [incorrectPosition, setIncorrectPosition] = useState(-1);

  const {module} = useParams();

  useEffect(() => {
    if (lives === 0) {
      handleGameOver();
    }
  }, [lives]);

  const prepareQuestions = (level) => {
    let questionsData;
    if (module === "timestable") {
      questionsData = generateTimesTableQuestions(level, 20);
    } else if (module === "addandsub") {
      questionsData = generateAdditionAndSubtractionQuestions(level, 20);
    }
    if (questionsData === null) {
      setGameWon(true)
    } else {
      setQuestions(questionsData.questions);
      setTime(questionsData.solveTime);
    }
  }

  useEffect(() => prepareQuestions(level), [level]);

  const playSuccess = () => {
    const audio = new Audio('/sounds/success.mp3');
    audio.type = "audio/mp3"
    audio.play();
  }

  const playError = () => {
    const audio = new Audio('/sounds/error.mp3');
    audio.play();
  }

  const handleStart = () => {
    prepareQuestions(1);
    setGameStarted(true);
    setGameOver(false);
    setLevel(1);
    setCurrentQuestion(0);
    setScore(0);
    setLives(3);
  }

  const handleAnswer = (answer) => {
    const question = questions[currentQuestion];

    if (question.correctAnswer === answer) {
      handleCorrectAnswer(question);
    } else {
      handleIncorrectAnswer(question, answer);
    }
  }

  const handleGameOver = () => {
    updateScores(score, module);
    updateHighestScore(score, module);
    setGameOver(true);
  }

  const handleCorrectAnswer = (question) => {
    playSuccess();
    setCorrectPosition(getCorrectPosition(question));
    setTimeout(() => {
      setCorrectPosition(-1);
      setScore(score + 10);
      setTimerKey(timerKey + 1);
      if (currentQuestion === questions.length - 1) {
        setLevelCompleted(true);
      } else {
        setCurrentQuestion(currentQuestion + 1)
      }
    }, 500);
  }

  const getCorrectPosition = (question) => {
    for (let i = 0; i < question.possibleAnswers.length; i++) {
      if (question.possibleAnswers[i] === question.correctAnswer) {
        return i;
      }
    }
  }

  const getIncorrectPosition = (question, suppliedAnswer) => {
    for (let i = 0; i < question.possibleAnswers.length; i++) {
      if (question.possibleAnswers[i] === suppliedAnswer) {
        return i;
      }
    }
  }

  const handleTimeExpired = () => {
    playError();
    setIncorrectPosition(-1)
    setTimerKey(timerKey + 1);
    setLives(lives - 1);
  }

  const handleIncorrectAnswer = (question, suppliedAnswer) => {
    playError();
    setIncorrectPosition(getIncorrectPosition(question, suppliedAnswer));
    setTimeout(() => {
      setIncorrectPosition(-1)
      setTimerKey(timerKey + 1);
      setLives(lives - 1);
    }, 500);
  }

  const handleStartNextLevel = () => {
    setLevel(level + 1);
    setCurrentQuestion(0);
    setLevelCompleted(false);
    setLives(lives + 1);
  }

  if (!gameStarted) {
    return (
      <div className="game-start-container">
        <GameStart onStart={handleStart} module={module}/>
      </div>
    );
  }

  if (gameWon) {
    return (
      <div className="game-container">
        <GameWon score={score} handleRestart={handleStart}/>
      </div>
    );
  }

  if (gameOver) {
    return (
      <div className="game-container">
        <GameOver score={score} handleRestart={handleStart} module={module}/>
      </div>
    );
  }

  if (levelCompleted) {
    return (
      <div className="game-container">
        <Level onStartNextLevel={handleStartNextLevel} score={score} lives={lives}/>
      </div>
    )
  }

  return (
    <div className="game-container">
      <Timer key={timerKey} duration={time} onTimeExpired={handleTimeExpired}/>
      <div className="container">
        <div className="question-section">
          <Question questionText={questions[currentQuestion].question}/>
        </div>
        <div className='answer-section'>
          <Answer
            possibleAnswers={(questions[currentQuestion].possibleAnswers)}
            handleAnswer={handleAnswer}
            correctPosition={correctPosition}
            incorrectPosition={incorrectPosition}
          />
        </div>
      </div>
      <div className="container">
        <Score score={score}/>
        <Lives lives={lives}/>
      </div>
    </div>
  );
}

export default StartGamePage;
