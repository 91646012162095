import React from 'react';

const Lives = ({ lives }) => {
  const hearts = [];

  for (let i = 0; i < lives; i++) {
    hearts.push(<span key={i}>&#x2764;&#xfe0f;</span>);
  }

  return <div className="lives">Lives: {hearts}</div>;
};

export default Lives;