const generateQuestions = (level, numberOfQuestions) =>
{
  const questions = [];
  let upperBound = 50;

  /*if (level <= 4) {
    upperBound = 20;
  } else if (level <= 8) {
    upperBound = 100;
  } else {
    return null;
  }
*/
  for (let i = 0; i < numberOfQuestions; i++) {
    let a = Math.floor(Math.random() * upperBound) + 1;
    let b = Math.floor(Math.random() * upperBound) + 1;
    const operator = Math.random() > 0.5 ? '+' : '-';
    let question;
    let correctAnswer;

    if (operator === '+' || a >= b) {
      question = `${a} ${operator} ${b}`;
      if (operator === '+') {
        correctAnswer = a + b;
      } else {
        correctAnswer = a - b;
      }
    } else {
      question = `${b} ${operator} ${a}`;
      correctAnswer = b - a;
    }

    const possibleAnswers = generatePossibleAnswers(correctAnswer, upperBound);
    questions.push({
      question,
      correctAnswer,
      possibleAnswers
    });
  }

  return questions;
}


const generatePossibleAnswers = (correctAnswer, upperBound) => {
  const possibleAnswers = new Set();

  while (possibleAnswers.size < 3) {
    let incorrectAnswer;
    if (upperBound < 50) {
      const randomOffset = Math.floor(Math.random() * 5) + 1;
      incorrectAnswer = correctAnswer + (Math.random() > 0.5 ? randomOffset : -randomOffset);
    } else {
      if (Math.random() > 0.5) {
        const randomOffset = Math.floor(Math.random() * (upperBound / 4)) + 1;
        incorrectAnswer = correctAnswer + (Math.random() > 0.5 ? randomOffset : -randomOffset);
      } else {
        const randomOffset = Math.floor(Math.random() * 3) + 1;
        incorrectAnswer = correctAnswer + 10 * ((Math.random() > 0.5 ? randomOffset : -randomOffset));

      }
    }
    if (incorrectAnswer >= 0 && incorrectAnswer <= upperBound * 2 && incorrectAnswer !== correctAnswer) {
      possibleAnswers.add(incorrectAnswer);
    }
  }

  possibleAnswers.add(correctAnswer);

  return Array.from(possibleAnswers).sort((a, b) => a - b);
}

const getSolveTime = (level) => {
  return 11 - level;
  /*if (level <= 4) {
    return 8 - (level - 1);
  } else if (level <= 8) {
    return 10 - (level - 4);
  }*/
}


export const generateAdditionAndSubtractionQuestions = (level, count) => {
  if (level > 8) {
    return null;
  }

  const questions = generateQuestions(level, count);
  const solveTime = getSolveTime(level);

  return {
    level,
    solveTime,
    questions,
  };
}