import React, { useState, useEffect } from 'react';

const Timer = ({ duration, onTimeExpired }) => {
  const [remainingTime, setRemainingTime] = useState(duration);
  const [lineWidth, setLineWidth] = useState(100);

  useEffect(() => {
    let intervalId;
    setRemainingTime(Math.min(remainingTime, duration));
    const percentage = remainingTime / duration;
    setLineWidth(percentage * 100);

    if (remainingTime > 0) {
      intervalId = setInterval(() => {
        setRemainingTime(remainingTime - 1);
      }, 1000);
    } else {
      onTimeExpired();
    }

    return () => clearInterval(intervalId);
  }, [remainingTime, onTimeExpired]);

  let color;
  if (remainingTime === duration) {
    color = 'green';
  } else if (remainingTime <= duration * 0.2) {
    color = 'red';
  } else if (remainingTime <= duration * 0.5) {
    color = 'orange';
  } else {
    color = 'green';
  }

  const lineStyle = {
    backgroundColor: color,
    height: '10px',
    width: `${lineWidth}%`,
    transition: 'width 1s linear',
  };

  return (
    <div className="timer-section">
      <div style={lineStyle}></div>
    </div>
  );
};

export default Timer;
