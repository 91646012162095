import React from "react";
import {Link} from "react-router-dom";
import {CookieConsent} from "react-cookie-consent";

const MainPage = () => {
  return (
    <div className="game-start-container">
      <div className="game-start">
        <h2>🚀 Welcome to FunMathsGame! 🎉 </h2>
        <p>We're thrilled to have you here and can't wait for you to start playing our exciting maths game. Whether you're looking to practice your maths skills or just have fun, this game is perfect for you! 😄</p>
        <p>Before we begin, we want to know which module you would like to choose from the following list:</p>
        <ul>
          <li className="scores">🌟 <Link to="/start-game/timestable">Times Table</Link></li>
          <li className="scores">🌟 <Link to="/start-game/addandsub">Addition & Subtraction</Link></li>
        </ul>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="acceptCookies"
        style={{ background: "#252d4a" }}>
        This website uses cookies to save your scores. By using this website, you agree to our use of cookies.
      </CookieConsent>
    </div>
  )
}
export default MainPage;