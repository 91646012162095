import React from "react";
import {getHighestScore, getScores} from "../../modules/scores";
import moment from "moment";
import {convertToEmojis} from "../../modules/uiutils";
import {Link} from "react-router-dom";

const GameStart = ({onStart, module}) => {
  const highestScore = getHighestScore(module);
  const latestScores = getScores(module);
  return (
    <div className="game-start">
      <div>
        <Link to="/">← back to main page</Link>
      </div>
      <h2>🚀 Learn {getSkill(module, true)} the Fun Way!</h2>
      {highestScore > 0 ? (
        <>
          <p>Welcome back to our amazing {getSkill(module)} game! 🤩 We're so proud of you for achieving a high score of {convertToEmojis(getHighestScore(module))} in your previous attempts! You're doing an awesome job at mastering the {getSkill(module)}. 👏</p>
          <p>We're excited to see you again and urge you to try again and beat your top score! 💪</p>
        </>): null}
      {latestScores.length > 0 ? (
          <>
            <p>Here's a quick summary of your previous attempts:</p>
            <ul>
              {latestScores.map((score, index) => (
                <li className="scores" key={index}>🌟 {convertToEmojis(score.score)} achieved {moment(score.dateTime).fromNow()}</li>))}
            </ul>
            <p>As you already know, each level has {convertToEmojis(20)} random multiple-choice questions. The game tests your {getSkill(module)} skills, and you must choose the correct answer from {convertToEmojis(4)} options. You start with {convertToEmojis(3)} lives ❤️❤️❤️, and you gain an extra ❤️ after each level. Remember, incorrect answers will cost you a life, while correct answers earn you {convertToEmojis(10)} points.</p>
            <p>To add to the excitement, each level gets progressively harder, with the time to complete each question decreasing. This feature helps to build your confidence and speed in recalling the {getSkill(module)}. 🚀</p>
            <p>So what are you waiting for? Let's start playing and beat your best score! 🏆</p>
          </>
        ) :
        <>
          <p>Welcome to our awesome game that helps you learn {getSkill(module)} in a fun way! 🤩 The game has {convertToEmojis(20)} questions in each level, and you need to choose the right answer from 4️⃣ options. Think carefully before answering. 🧐</p>
          <p>You start with 3️⃣ lives ❤️❤️❤️, and you get an extra ❤️ after each level. You get 🔟 points for every correct answer, but if you get the answer wrong, you lose a life ❤️‍🔥. The maximum score you can get is {convertToEmojis(1600)} points. 🎯</p>
          <p>Each level gets harder, but it also gets more exciting! 🤩 You'll get faster at answering as you practice more, and you'll be a maths wizard in no time! ⚡</p>
          <p>So what are you waiting for? Let's start playing and have some fun while learning! 🎉</p>
        </>}
      <button onClick={onStart} className="game-start-button">Start Now</button>
    </div>
  );
}

const getSkill = (module, heading = false) => {
  if (module === 'timestable') {
    if (heading) {
      return 'Times Table';
    }
    return 'times table ';
  } else if (module === 'addandsub') {
    if (heading) {
      return 'Addition & Subtraction';
    }
    return 'addition and subtraction';
  }
}

export default GameStart;
