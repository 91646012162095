import Cookies from "js-cookie";

export const updateScores = (score, module) => {
  const now = new Date().toISOString(); // Get current date and time in ISO string format
  const cookieName = getCookieName('scores', module);
  const existingScores = JSON.parse(Cookies.get(cookieName) || '[]'); // Get existing scores from cookie or initialize empty array

  existingScores.push({ score, dateTime: now });

  // If there are more than 10 scores, remove the oldest score
  if (existingScores.length > 10) {
    existingScores.shift();
  }

  console.log("Setting scores in cookie", cookieName, existingScores);
  Cookies.set(cookieName, JSON.stringify(existingScores));
};

export const getScores = (module) => {
   // Get existing scores from cookie or initialize empty array
  const cookieName = getCookieName('scores', module);
  console.log("Getting scores from cookie", cookieName);
  return JSON.parse(Cookies.get(cookieName) || '[]').reverse();
};

export const updateHighestScore = (score, module) => {
  const cookieName = getCookieName('highestScore', module);
  const existingHighestScore = parseInt(Cookies.get(cookieName)) || 0; // Get existing highest score from cookie or initialize to 0
  const currentScore = parseInt(score);

  // If the current score is higher than the existing highest score, update the cookie
  if (currentScore > existingHighestScore) {
    Cookies.set(cookieName, currentScore);
  }
};

export const getHighestScore = (module) => {
  const cookieName = getCookieName('highestScore', module);
  return parseInt(Cookies.get(cookieName)) || 0; // Get existing highest score from cookie or initialize to 0
}

const getCookieName = (feature, module) => {
  return `${feature}-${module}`;
}