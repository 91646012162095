import React from 'react';
import {convertToEmojis} from "../../modules/uiutils";
import {Link} from "react-router-dom";

const GameWon = ({ score, handleRestart }) => {
  return (
    <div className="game-over">
      <div>
        <Link to="/">← back to main page</Link>
      </div>
      <div className="game-over-text">
        <h1>You Win! 🤩🚀</h1>
        <p>Congratulations, you did it! 🎉🎉🎉 You achieved the maximum score of {convertToEmojis(score)} points and won the game! 🏆🎉🎊</p>
        <p>Wow, you're amazing! 👏 But can you do it again? 😏</p>
        <p>I bet you can't win twice in a row! </p>
        <p>Are you up for the challenge? 💪 </p>
      </div>
      <button className="restart-button" onClick={handleRestart}>Start Again Now</button>
    </div>
  );
};

export default GameWon;