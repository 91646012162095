export const generateTimesTableQuestions = (level, count) => {

  const questions = [];

  const solveTime = 11 - level;

  if (solveTime < 3) {
    return null;
  }

  for (let i = 0; i < count; i++) {
    let x, y, operator, answer;
    const possibleAnswers = [];

    const random = Math.random();
    if (random < 0.5) {
      // multiplication problem
      x = Math.floor(Math.random() * 12) + 1;
      y = Math.floor(Math.random() * 12) + 1;
      operator = "x";
      answer = x * y;
    } else {
      // division problem
      answer = Math.floor(Math.random() * 12) + 1;
      y = Math.floor(Math.random() * 12) + 1;
      x = answer * y;
      operator = ":";
    }

    // generate possible answers
    const answerPosition = Math.floor(Math.random() * 4);
    for (let j = 0; j < 4; j++) {
      if (j === answerPosition) {
        possibleAnswers.push(answer);
      } else {
        let possibleAnswer;
        do {
          const randomOperator = Math.random();
          if (operator === "x") {
            const deviation = Math.floor(Math.random() * 4);
            if (randomOperator < 0.5 && y > deviation) {
              possibleAnswer = x * (y - deviation);
            } else {
              possibleAnswer = x * (y + deviation);
            }
          } else {
            const deviation = Math.floor(Math.random() * 4);
            if (randomOperator < 0.5 && answer > deviation) {
              possibleAnswer = answer - deviation;
            } else {
              possibleAnswer = answer + deviation;
            }
          }
        } while (
          possibleAnswer === answer ||
          possibleAnswers.includes(possibleAnswer)
          );
        possibleAnswers.push(possibleAnswer);
      }
    }

    possibleAnswers.sort((a, b) => a - b);

    questions.push({
      question: `${x} ${operator} ${y}`,
      possibleAnswers,
      correctAnswer: answer,
    });
  }

  return {
    level,
    solveTime,
    questions,
  };
};
