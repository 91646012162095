import React, {useEffect} from "react";

const Answer = ({possibleAnswers, handleAnswer, correctPosition, incorrectPosition}) => {

  if (possibleAnswers) {
    return (
      <>
        {possibleAnswers.map((answer, index) => (<button key={index}
                                                         className={`answer-button ${correctPosition === index ? 'correct' : ''}${incorrectPosition === index ? 'incorrect' : ''}`}
                                                         onClick={() => handleAnswer(answer)}>{answer}</button>))}
      </>
    )
  }
  return null;
};

export default Answer;